import React from 'react';

export default function Error() {
  React.useEffect(() => {
    const flowId = sessionStorage.getItem('flowId');
    window.top.location.href = `/login?flowId=${flowId}&ssoerror=true`;
  }, []);

  return <></>;
}
